import React from 'react'
import Layout from 'components/layout'
import Login from 'components/login'

const IndexPage = (props) => {
  return (
    <Layout>
      <Login {...props} />
    </Layout>
  )
}

export default IndexPage
