import React, {useEffect, useRef} from 'react'
import {renderButton} from 'lib/gApi'
import {useDispatch} from 'react-redux'
import {navigate} from 'gatsby'
import {updateAuthUser} from 'actions/auth'
import * as styles from 'styles/components/button.module.css'

const Login = ({location}) => {
  const buttonRef = useRef()
  const dispatch = useDispatch()

  const isRootPage = location?.pathname && location.pathname === '/'

  useEffect(() => {
    renderButton(buttonRef.current, (user) => {
      dispatch(updateAuthUser(user))
      if (isRootPage) {
        navigate('/app')
      }
    })
  }, [dispatch, isRootPage])

  return (
    <div>
      <h2>Login page</h2>
      <div className={styles.loginButtonWrapper} ref={buttonRef} />
    </div>
  )
}

export default Login
