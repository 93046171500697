import jwtDecode from 'jwt-decode'
import {memoize} from './memoize'
import gAuth from 'lib/gauth.json'

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'
const gCredentials = gAuth[activeEnv] || {}

const loadGoogleScript = () =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.async = true
    script.defer = true
    script.type = 'text/javascript'
    script.src = 'https://accounts.google.com/gsi/client'

    script.onload = resolve
    script.onerror = reject

    const parent =
      document.getElementsByTagName('HEAD')[0] || document.getElementsByTagName('BODY')[0]

    parent.appendChild(script)
  })

const loadGoogleScriptCached = memoize(loadGoogleScript)

export const renderButton = (buttonDiv, callback) => {
  loadGoogleScriptCached().then(() => {
    window.google.accounts.id.initialize({
      client_id: gCredentials.clientId,
      hosted_domain: 'railsware.com',
      scope: 'profile email openid',
      ux_mode: 'popup',
      callback: (response) => {
        if (response.credential) {
          const info = (() => {
            try {
              return jwtDecode(response.credential)
            } catch (e) {
              console.error('Invalid jwt', response)
              return null
            }
          })()

          if (info) {
            callback.call(null, {
              info,
              response
            })
          }
        } else {
          console.error('Invalid response', response)
        }
      }
    })

    window.google.accounts.id.renderButton(buttonDiv, {
      type: 'standard',
      theme: 'filled_blue',
      size: 'large',
      text: 'continue_with'
    })
  })
}
